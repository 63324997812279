import { useParams, useLocation, useNavigate, Navigate } from "react-router-dom";
import { useState, useEffect, useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import { Alert, Tab, Tabs, Table, Row, Col, Nav } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import {FormControl, Spinner, ToastContainer, Toast} from 'react-bootstrap';

import { HostName, ResponseReslt, TaskContext } from './global/constants';
import { getTaskList } from './global/functions';
import ListJobTasks from './ListJobTasks';
import AddNewTask from "./AddNewTask";
import { tab } from "@testing-library/user-event/dist/tab";


function App() {
  let { id } = useParams();
  let location = useLocation();
  var [TaskStatus, setTaskStatus] = useState({
    currUser: ''
  });
  let [busy, setBusy] = useState(true);
  let [show_message, setShowMessage] = useState(false);
  let [toast_message, setShowToastMessage] = useState('');

  var [Tasks, setTasks] = useState(null);
  var [TaskList, setTaskList] = useState(null);
  var [AddNewTaskVisabled, setAddNewTaskVisabled] = useState(false);
  let [NavActiveKey, setNavActiveKey] = useState('NST Processing');

  let owner = useRef();
  let [Users, setUsers] = useState([]);

  let navigate = useNavigate();

  let currUser = location.state != null ? location.state.currUser : null;

  let handlerTabSelected = (eventKey) => {
    const _tasks = [];

    
    switch (eventKey) {
      case 'NST Processing':
        TaskList.filter(element => ((element.Manufacturer === 'NST') && ((element.Status === 'Processing') || (element.Status === 'Waitting SPEC') || (element.Status === 'Not yet started')))).forEach(
          (elem) => {
            let v = Object.assign({}, elem);
            //delete v.Manufacturer;

            if((owner.current.value.toLowerCase() === elem.Owner.toLowerCase()) || (owner.current.value === ''))
            {
              _tasks.push(v);
            }

          }
        );
        break;

      case 'NST Wait for PO':
        TaskList.filter(element => ((element.Manufacturer === 'NST') && (element.Status === 'Waitting PO'))).forEach(
          (elem) => {
            let v = Object.assign({}, elem);
            //delete v.Manufacturer;

            if((owner.current.value.toLowerCase() === elem.Owner.toLowerCase()) || (owner.current.value === '')){
              _tasks.push(v);
            }
          }
        );
        break;

      case 'SYNAX Processing':
        TaskList.filter(element => ((element.Manufacturer === 'SYNAX') && ((element.Status === 'Processing') || (element.Status === 'Waitting SPEC') || (element.Status === 'Not yet started')))).forEach(
          (elem) => {
            let v = Object.assign({}, elem);
            //delete v.Manufacturer;

            if((owner.current.value.toLowerCase() === elem.Owner.toLowerCase()) || (owner.current.value === '')){
              _tasks.push(v);
            }
          }
        );
        break;

      case 'SYNAX Wait PO':
        TaskList.filter(element => ((element.Manufacturer === 'NST') && (element.Status === 'Waitting PO'))).forEach(
          (elem) => {
            let v = Object.assign({}, elem);
            //delete v.Manufacturer;

            if((owner.current.value.toLowerCase() === elem.Owner.toLowerCase()) || (owner.current.value === '')){
              _tasks.push(v);
            }
          }
        );
        break;

      case 'ON HOLD':
        TaskList.filter(element => (element.Status === 'Pending')).forEach(
          (elem) => {
            let v = Object.assign({}, elem);
            //delete v.Manufacturer;

            if((owner.current.value.toLowerCase() === elem.Owner.toLowerCase()) || (owner.current.value === '')){
              _tasks.push(v);
            }
          }
        );
        break;

      case 'NST closed':
        TaskList.filter(element => ((element.Manufacturer === 'NST') && (element.Status === 'Close'))).forEach(
          (elem) => {
            let v = Object.assign({}, elem);
            //delete v.Manufacturer;

            if((owner.current.value.toLowerCase() === elem.Owner.toLowerCase()) || (owner.current.value === '')){
              _tasks.push(v);
            }
          }
        );
        break;

      case 'SYNAX closed':
        TaskList.filter(element => ((element.Manufacturer === 'SYNAX') && (element.Status === 'Close'))).forEach(
          (elem) => {
            let v = Object.assign({}, elem);
            //delete v.Manufacturer;

            if((owner.current.value.toLowerCase() === elem.Owner.toLowerCase()) || (owner.current.value === '')){
              _tasks.push(v);
            }
          }
        );
        break;
    }

    setTasks(_tasks);
    setNavActiveKey(eventKey);

    switch (eventKey) {
      case 'requirements':
        /*
        postData(HostName + '/JobTask/get-list', {'RequestUserId': currUser.Id, IsListAll: false})
        .then((response) => {
          if(response.ok){
            response.json().then((result) => {
              //console.log('Result:', result);
              if(result.Result === 'Success'){
                TaskList = result.Data;
                const _tasks = [];

                TaskList.filter(element => (element.TaskType === 'Requirement')).forEach(
                  (elem) => {
                    let v = Object.assign({}, elem);
                    delete v.TaskType;
                    v.Status = v.RequirementStatus;
                    delete v.RequirementStatus;
                    v.UserIds = v.UserIdsReq;
                    delete v.UserIdsReq;
                    _tasks.push(v);
                  }
                );
                setTasks( _tasks );
              }
            }).catch((error) => {
              console.error('Error:', error);
            });
          }
        });
        */
        break;

      case 'issues':
        /*
        postData(HostName + '/JobTask/get-list', {'RequestUserId': currUser.Id, IsListAll: false})
        .then((response) => {
          if(response.ok){
            response.json().then((result) => {
              //console.log('Result:', result);
              if(result.Result === 'Success'){
                TaskList = result.Data;
                const _tasks = [];

                TaskList.filter(element => (element.TaskType === 'Issue')).forEach(
                  (elem) => {
                    let v = Object.assign({}, elem);
                    delete v.TaskType;
                    v.Status = v.IssueStatus;
                    delete v.IssueStatus;
                    v.UserIds = v.UserIdsIssue;
                    delete v.UserIdsIssue;
                    _tasks.push(v);
                  }
                );
                setTasks( _tasks );
              }
            }).catch((error) => {
              console.error('Error:', error);
            });
          }
        });
        */
        break;

      case 'requirements_all_user':
        if (currUser.Priority !== 'Administrator') break;
        /*
        postData(HostName + '/JobTask/get-list', {'RequestUserId': currUser.Id, IsListAll: true})
        .then((response) => {
          if(response.ok){
            response.json().then((result) => {
              //console.log('Result:', result);
              if(result.Result === 'Success'){
                TaskList = result.Data;
                const _tasks = [];

                TaskList.filter(element => (element.TaskType === 'Requirement')).forEach(
                  (elem) => {
                    let v = Object.assign({}, elem);
                    //delete v.TaskType;
                    v.Status = v.RequirementStatus;
                    delete v.RequirementStatus;
                    v.UserIds = v.UserIdsReq;
                    delete v.UserIdsReq;
                    _tasks.push(v);
                  }
                );
                setTasks( _tasks );
              }
            }).catch((error) => {
              console.error('Error:', error);
            });
          }
        });
        */
        break;

      case 'issues_all_user':
        if (currUser.Priority !== 'Administrator') break;
        /*
        postData(HostName + '/JobTask/get-list', {'RequestUserId': currUser.Id, IsListAll: true})
        .then((response) => {
          if(response.ok){
            response.json().then((result) => {
              //console.log('Result:', result);
              if(result.Result === 'Success'){
                TaskList = result.Data;
                const _tasks = [];

                TaskList.filter(element => (element.TaskType === 'Issue')).forEach(
                  (elem) => {
                    let v = Object.assign({}, elem);
                    delete v.TaskType;
                    v.Status = v.IssueStatus;
                    delete v.IssueStatus;
                    v.UserIds = v.UserIdsIssue;
                    delete v.UserIdsIssue;
                    _tasks.push(v);
                  }
                );
                setTasks( _tasks );
              }
            }).catch((error) => {
              console.error('Error:', error);
            });
          }
        });
        */
        break;

      default:
        break;
    }
    
  }

  let handlerKeyUpForOwner = (e) => {
    if(e.keyCode !== 13) return;

    handlerTabSelected(NavActiveKey);
  }

  let refreshTask = (task) => {
    for(let i=0; i<TaskList.length; i++){
      if(TaskList[i].Id === task.Id){
        const tasklist = [...TaskList];
        
        tasklist[i] = task;
        setTaskList(tasklist);
        break;
      }
    }
  };

  let AllRequirements= [
    <h1 key='1'>123</h1>,
    <h1 key='2'>456</h1>
  ];
  //let TaskList = null;
  let ModelList = null;
  //let Tasks = null;

  useEffect(()=>{
    setTaskStatus({currUser: currUser});
    /*
    postData(HostName + '/JobTask/get-list_model')
    .then((response) => {
      if(response.ok){
        response.json().then((result) => {
          //console.log('Result:', result);
          if(result.Result === 'Success'){
            ModelList = result.Data;
          }
        }).catch((error) => {
          console.error('Error:', error);
        });
      }
    });
    */

    getTaskList({ 'RequestUserId': currUser.Id })
    .then((response) => {
      if (response.ok) {
        response.json().then((result) => {
          //console.log('Result:', result);
          if (result.Result !== ResponseReslt.Success){
            setBusy(false);
            setShowToastMessage(result.Message);
            setShowMessage(true);
            return;
          }
          if (result.Result === ResponseReslt.Success) {
            for (let i = 0; i < result.Data.List.length; i++) {
              let hm = result.Data.List[i].HandlerModel;

              result.Data.List[i].Id = i;
              result.Data.List[i].Manufacturer = ((hm === 'S9') || (hm === 'S9+HS') || (hm === 'SX2400')) ? 'SYNAX' : 'NST';
            }

            const _tasklist = result.Data.List;
            setTaskList(_tasklist);
            const _tasks = [];

            _tasklist.filter(element => ((element.Manufacturer === 'NST') && ((element.Status === 'Processing') || (element.Status === 'Waitting SPEC') || (element.Status ==='Not yet started')))).forEach(
              (elem) => {
                let v = Object.assign({}, elem);
                //delete v.Manufacturer;

                _tasks.push(v);
              }
            );
            setTasks(_tasks);

            let _users = [];
            for(let i in result.Data.UserList){
              _users.push(result.Data.UserList[i].Account);
            }
            setUsers(_users);
            
            setBusy(false);
          }
        }).catch((error) => {
          console.error('Error:', error);
        });
      }
    });


  }, []);

  if(location.state == null){
    return ( <Navigate to="/" /> );
  }

  return (
    <TaskContext.Provider value={[TaskStatus, setTaskStatus]}>
      <>
        <div className="App" hidden={busy}>
          <Row md="10">
            <Col>
            </Col>
            <Col lg="2">
              <Button variant="primary" disabled={TaskStatus.currUser.Priority !== 'Administrator'} onClick={() => { setAddNewTaskVisabled(true); }}>Add New Task</Button>{' '}
            </Col>
            <Col lg="2">
            <InputGroup className="mb-3" disabled={TaskStatus.currUser.Priority !== 'Administrator'}>
              <InputGroup.Text>Owner</InputGroup.Text>
              <FormControl onKeyUp={handlerKeyUpForOwner} type="text" ref={owner} placeholder="請輸入Owner" disabled={TaskStatus.currUser.Priority !== 'Administrator'} />
            </InputGroup>
            </Col>
            <Col md="auto">
              {/*
              <Badge bg="danger"> {TaskStatus.currUser.Name} || {location.hash} || {location.search}</Badge>
              */}
              <Button variant="danger"> {TaskStatus.currUser.Name}</Button>
            </Col>
            <Col lg="1">
              <Button variant="primary" onClick={() => {navigate('/');} }>Logout</Button>{' '}
            </Col>
          </Row>
          <AddNewTask show={{display: AddNewTaskVisabled, callback: setAddNewTaskVisabled}} user={TaskStatus.currUser}/>
        
          {/*
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Edit <code>src/App.js</code> and save to reload.
            </p>
            <a
              className="App-link"
              href="https://rarrrrtttt"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn React
            </a>
          </header>
          */}
          <div style={!AddNewTaskVisabled ? { display: "inline" } : { display: "none" }}>
            <Nav variant="tabs" defaultActiveKey="NST Processing" onSelect={handlerTabSelected} className="justify-content-center">
              <Nav.Item>
                <Nav.Link eventKey="NST Processing">NST Processing</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="NST Wait for PO">NST Wait for PO</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="SYNAX Processing">SYNAX Processing</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="SYNAX Wait PO">SYNAX Wait PO</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="ON HOLD">ON HOLD</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="NST closed">NST closed</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="SYNAX closed">SYNAX closed</Nav.Link>
              </Nav.Item>
            </Nav>
            <ListJobTasks list={Tasks} user_id={TaskStatus.currUser.Id} eventKey={NavActiveKey} callback={refreshTask}/>
          </div>
        
          {/*
          <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3" fill onSelect={handlerTabSelected}>
            <Tab eventKey="requirements" title="Requirements">
              <Table striped bordered hover responsive>
              </Table>
            </Tab>
            <Tab eventKey="issues" title="Issues">
              <Table striped bordered hover responsive>
              </Table>
            </Tab>
            <Tab eventKey="requirements_all_user" title="All Requirements" disabled={TaskStatus.currUser.Priority !== 'Administrator'}>
              <Table striped bordered hover responsive>
              </Table>
            </Tab>
            <Tab eventKey="issues_all_user" title="All Issues" disabled={TaskStatus.currUser.Priority !== 'Administrator'}>
              <Table striped bordered hover responsive>
              </Table>
            </Tab>
          </Tabs>
        */}
        </div>
        <Spinner animation="border" hidden={!busy}/>
        <Spinner animation="grow" hidden={!busy}/>
        <ToastContainer
          className="p-3"
          position='top-center'
          style={{ zIndex: 1 }}
        >
          <Toast bg="danger" onClose={() => setShowMessage(false)} show={show_message} delay={3000} autohide>
            <Toast.Header closeButton={true}>
              <strong className="me-auto">Post Error</strong>
              {/*
              <small>11 mins ago</small>
              */}
            </Toast.Header>
            <Toast.Body className={'danger' === 'Dark' && 'text-white'}>{toast_message}</Toast.Body>
          </Toast>
        </ToastContainer>
      </>
    </TaskContext.Provider>
  );
}

export default App;
