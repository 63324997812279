import React from 'react';
import { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Accordion, Row, Col, Container, Card, Button, Modal, Form } from 'react-bootstrap';
import { ResponseReslt } from './global/constants';
import { updateTask } from './global/functions'


var ListJobTasks = React.memo((props) => {
  //console.log(props.list === undefined ? '' : props.list);
  /*
  let tbody = [];
  

  if(props.list !== undefined){
    if(props.list !== null){
      props.list.forEach( (task) => {
        tbody.push(
          <tr>
            <td>{task.Id}</td>
            <td>{task.Title}</td>
            <td>{task.PmName}</td>
            <td>{task.Status}</td>
            <td>{task.WaitFor}</td>
            <td>{task.CompanyName}</td>
          </tr>
        );
      });
    }
  }
  */
  let [curr_task, setCurrentTask] = useState(null);

  let visit_date = useRef();
  const [modal_visitdate_show, setModalVisitDateShow] = useState(false);
  let handlerEditVisitDate = (task_id) => {
    setCurrentTask(props.list.find(elem => elem.Id == task_id));
    setModalVisitDateShow(true);
  };
  const handleModalVisitDateClose = () => setModalVisitDateShow(false);
  const handleModalVisitDateConfirm = () => {
    let data = {
      'UserId':props.user_id,
      'ReferenceNO': curr_task.ReferenceNO,
      'HandlerModel': curr_task.HandlerModel,
      'UpdateType': 'VisitCustomerDate',
      'UpdateData':{
        'VisitCustomerDate': visit_date.current.value,
      }
    }

    updateTask(data)
    .then((response) => {
      if(response.ok){
        response.json().then((result) => {
          if(result.Result !== ResponseReslt.Success) {
            alert(result.Message);
            setModalVisitDateShow(false);
            return;
          }

          curr_task.VisitCustomerDate = visit_date.current.value;
          setCurrentTask(curr_task);
          props.callback(curr_task);
          setModalVisitDateShow(false);
        }).catch((error) => {
          console.error('Error:', error);
        });
      }
    });

  };
  //const handleShow = () => setModalShow(true);
  
  let update_date = useRef('');
  let update = useRef();
  const [modal_update_show, setModalUpdateShow] = useState(false);
  let handlerAddUpdate = (task_id) => {
    setCurrentTask(props.list.find(elem => elem.Id == task_id));
    setModalUpdateShow(true);
  };
  const handleModalUpdateClose = () => setModalUpdateShow(false);
  const handleModalUpdateConfirm = () => {
    if(update.current.value === '')
    {
      return;
    }

    if(update_date.current.value === '')
    {
      return;
    }

    let new_value = (curr_task.Update.split('\n').length + 1).toString() + '. ';
    new_value += update_date.current.value.replace(/[-]/g, '').substring(4) + ', ';
    new_value += update.current.value;

    let data = {
      'UserId':props.user_id,
      'ReferenceNO': curr_task.ReferenceNO,
      'HandlerModel': curr_task.HandlerModel,
      'UpdateType': 'Update',
      'UpdateData':{
        'Update': curr_task.Update + '\n' + new_value,
      }
    }

    updateTask(data)
    .then((response) => {
      if(response.ok){
        response.json().then((result) => {
          if(result.Result !== ResponseReslt.Success) {
            alert(result.Message);
            setModalUpdateShow(false);
            return;
          }

          curr_task.Update += '\n' + new_value;
          setCurrentTask(curr_task);
          setModalUpdateShow(false);
          props.callback(curr_task);
        }).catch((error) => {
          console.error('Error:', error);
        });
      }
    });

  };

  let swresult = useRef();
  let swresult_date = useRef();
  let swresult_ok = useRef('NG');
  const [modal_swresult_show, setModalSwResultShow] = useState(false);
  let handlerAddSwResult = (task_id) => {
    setCurrentTask(props.list.find(elem => elem.Id == task_id));
    setModalSwResultShow(true);
  };
  const handleModalSwResultClose = () => setModalSwResultShow(false);
  const handleModalSwResultConfirm = () => {
    if(swresult.current.value === '')
    {
      return;
    }

    if(swresult_date.current.value === '')
    {
      return;
    }

    let new_value = (curr_task.SWResult.split('\n').length + 1).toString() + '. ';
    new_value += swresult_date.current.value.replace(/[-]/g, '').substring(4) + ', ';
    new_value += swresult.current.value + ' ';
    new_value += swresult_ok.current.value;

    let data = {
      'UserId':props.user_id,
      'ReferenceNO': curr_task.ReferenceNO,
      'HandlerModel': curr_task.HandlerModel,
      'UpdateType': 'SWResult',
      'UpdateData':{
        'SWResult': curr_task.SWResult + '\n' + new_value,
      }
    }

    updateTask(data)
    .then((response) => {
      if(response.ok){
        response.json().then((result) => {
          if(result.Result !== ResponseReslt.Success) {
            alert(result.Message);
            setModalSwResultShow(false);
            return;
          }

          curr_task.SWResult += '\n' + new_value;
          curr_task.NumberOfSoftwareVersionModifications = curr_task.SWResult.split('\n').length;
          setCurrentTask(curr_task);
          setModalSwResultShow(false);
          props.callback(curr_task);
        }).catch((error) => {
          console.error('Error:', error);
        });
      }
    });

  };

  let ball_at = useRef('PSMC_Service');
  const [modal_ballat_show, setModalBallAtShow] = useState(false);
  let handlerEditBallAt = (task_id) => {
    setCurrentTask(props.list.find(elem => elem.Id == task_id));
    setModalBallAtShow(true);
  };
  const handleModalBallAtClose = () => setModalBallAtShow(false);
  const handleModalBallAtConfirm = () => {
    let data = {
      'UserId':props.user_id,
      'ReferenceNO': curr_task.ReferenceNO,
      'HandlerModel': curr_task.HandlerModel,
      'UpdateType': 'BallAt',
      'UpdateData':{
        'BallAt': ball_at.current.value,
      }
    }

    updateTask(data)
    .then((response) => {
      if(response.ok){
        response.json().then((result) => {
          if(result.Result !== ResponseReslt.Success) {
            alert(result.Message);
            setModalBallAtShow(false);
            return;
          }

          curr_task.BallAt = ball_at.current.value;
          setCurrentTask(curr_task);
          setModalBallAtShow(false);
          props.callback(curr_task);
        }).catch((error) => {
          console.error('Error:', error);
        });
      }
    });

  };

  let who = useRef('');
  const [modal_who_show, setModalWhoShow] = useState(false);
  let handlerEditWho = (task_id) => {
    setCurrentTask(props.list.find(elem => elem.Id == task_id));
    setModalWhoShow(true);
  };
  const handleModalWhoClose = () => setModalWhoShow(false);
  const handleModalWhoConfirm = () => {
    let data = {
      'UserId':props.user_id,
      'ReferenceNO': curr_task.ReferenceNO,
      'HandlerModel': curr_task.HandlerModel,
      'UpdateType': 'Who',
      'UpdateData':{
        'Who': who.current.value,
      }
    }

    updateTask(data)
    .then((response) => {
      if(response.ok){
        response.json().then((result) => {
          if(result.Result !== ResponseReslt.Success) {
            alert(result.Message);
            setModalWhoShow(false);
            return;
          }

          curr_task.Who = who.current.value;
          setCurrentTask(curr_task);
          setModalWhoShow(false);
          props.callback(curr_task);
        }).catch((error) => {
          console.error('Error:', error);
        });
      }
    });

  };

  let status = useRef('Not yet started');
  const [modal_status_show, setModalStatusShow] = useState(false);
  let handlerEditStatus = (task_id) => {
    setCurrentTask(props.list.find(elem => elem.Id == task_id));
    setModalStatusShow(true);
  };
  const handleModalStatusClose = () => setModalStatusShow(false);
  const handleModalStatusConfirm = () => {
    let data = {
      'UserId':props.user_id,
      'ReferenceNO': curr_task.ReferenceNO,
      'HandlerModel': curr_task.HandlerModel,
      'UpdateType': 'Status',
      'UpdateData':{
        'Status': status.current.value,
      }
    }

    updateTask(data)
    .then((response) => {
      if(response.ok){
        response.json().then((result) => {
          if(result.Result !== ResponseReslt.Success) {
            alert(result.Message);
            setModalStatusShow(false);
            return;
          }

          curr_task.Status = status.current.value;
          setCurrentTask(curr_task);
          props.callback(curr_task);

          switch (props.eventKey) {
            case 'NST Processing':
            case 'SYNAX Processing':
              if( (status.current.value === 'Waitting PO') || (status.current.value === 'Pending') || (status.current.value === 'Close') ){
                for(let i=0; i<props.list.length; i++){
                  if(props.list[i].Id == curr_task.Id){
                    const x = props.list.splice(i, 1);
                    break;
                  }
                }
                
              }
              break;
      
            case 'NST Wait for PO':
            case 'SYNAX Wait PO':
              if(status.current.value !== 'Waitting PO'){
                for(let i=0; i<props.list.length; i++){
                  if(props.list[i].Id == curr_task.Id){
                    const x = props.list.splice(i, 1);
                    break;
                  }
                }
                
              }
              break;
      
            case 'ON HOLD':
              if(status.current.value !== 'Pending'){
                for(let i=0; i<props.list.length; i++){
                  if(props.list[i].Id == curr_task.Id){
                    const x = props.list.splice(i, 1);
                    break;
                  }
                }
              }
              break;
      
            case 'NST closed':
            case 'SYNAX closed':
              if(status.current.value !== 'Close'){
                for(let i=0; i<props.list.length; i++){
                  if(props.list[i].Id == curr_task.Id){
                    const x = props.list.splice(i, 1);
                    break;
                  }
                }
              }
              break;

          }

          setModalStatusShow(false);
        }).catch((error) => {
          console.error('Error:', error);
        });
      }
    });

  };

  let closed_date = useRef('');
  const [modal_closeddate_show, setModalClosedDateShow] = useState(false);
  let handlerEditClosedDate = (task_id) => {
    setCurrentTask(props.list.find(elem => elem.Id == task_id));
    setModalClosedDateShow(true);
  };
  const handleModalClosedDateClose = () => setModalClosedDateShow(false);
  const handleModalClosedDateConfirm = () => {
    let data = {
      'UserId':props.user_id,
      'ReferenceNO': curr_task.ReferenceNO,
      'HandlerModel': curr_task.HandlerModel,
      'UpdateType': 'ClosedDate',
      'UpdateData':{
        'ClosedDate': closed_date.current.value,
      }
    }

    updateTask(data)
    .then((response) => {
      if(response.ok){
        response.json().then((result) => {
          if(result.Result !== ResponseReslt.Success) {
            alert(result.Message);
            setModalClosedDateShow(false);
            return;
          }

          curr_task.ClosedDate = closed_date.current.value;
          setCurrentTask(curr_task);
          setModalClosedDateShow(false);
          props.callback(curr_task);
        }).catch((error) => {
          console.error('Error:', error);
        });
      }
    });

  };

  let remark = useRef('');
  const [modal_remark_show, setModalRemarkShow] = useState(false);
  let handlerEditRemark = (task_id) => {
    setCurrentTask(props.list.find(elem => elem.Id == task_id));
    setModalRemarkShow(true);
  };
  const handleModalRemarkClose = () => setModalRemarkShow(false);
  const handleModalRemarkConfirm = () => {
    let data = {
      'UserId':props.user_id,
      'ReferenceNO': curr_task.ReferenceNO,
      'HandlerModel': curr_task.HandlerModel,
      'UpdateType': 'RemarkImage',
      'UpdateData':{
        'RemarkImage': remark.current.value, /*.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t"),*/
      }
    }

    updateTask(data)
    .then((response) => {
      if(response.ok){
        response.json().then((result) => {
          if(result.Result !== ResponseReslt.Success) {
            alert(result.Message);
            setModalRemarkShow(false);
            return;
          }

          curr_task.RemarkImage = remark.current.value;
          setCurrentTask(curr_task);
          setModalRemarkShow(false);
          props.callback(curr_task);
        }).catch((error) => {
          console.error('Error:', error);
        });
      }
    });

  };

  return (
    <>
      <Accordion className="text-start" flush>
        {
          (props.list === undefined) || (props.list === null) ? '' :
              props.list.map( task => 
                //<Accordion.Item eventKey={task.ReferenceNO} key={task.ReferenceNO}>
                <Accordion.Item eventKey={task.Id} key={task.Id}>
                  <Accordion.Header>
                    <Container fluid style={(task.Status == 'Waitting SPEC') ? { backgroundColor: 'rgb(182, 227, 232)' } : ((task.Status == 'Waitting PO') ? { backgroundColor: 'rgb(248, 179, 174)' } : ((task.Status == 'Pending') ? { backgroundColor: 'rgb(217, 217, 217)' } : ((task.Status == 'Close') ? { backgroundColor: 'rgb(165, 277, 186)' } : { backgroundColor: 'rgb(255, 255, 255)' })))}>
                      <Row style={{color:'rgb(230, 145, 56)'}}>
                        <Col>Owner:</Col>
                        <Col>CaseType:</Col>
                        <Col>HandlerModel:</Col>
                        <Col>{task.CaseType === 'SW_Request' ?'RequestDescription' : 'Issue'}:</Col>
                        <Col>Update:</Col>
                        <Col>Status:</Col>
                        <Col>BallAt:</Col>
                        <Col>Who:</Col>
                      </Row>
                      <Row>
                        <Col>{task.Owner}</Col>
                        <Col>{task.CaseType}</Col>
                        <Col>{task.HandlerModel}</Col>
                        <Col>{task.CaseType === 'SW_Request' ? task.RequestDescription :task.Issue}</Col>
                        <Col>{task.Update.split('\n').slice(-1)}</Col>
                        <Col>{task.Status}</Col>
                        <Col>{task.BallAt}</Col>
                        <Col>{task.Who}</Col>
                      </Row>
                    </Container>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Card>
                      {/*
                      <Card.Header as="h5">{task.Status}</Card.Header>
                      <Card.Body>
                        <Card.Title>{task.ReferenceNO}</Card.Title>
                        <Card.Text>{task.CaseType === 'SW_Request' ? task.RequestDescription : task.Issue}</Card.Text>
                      </Card.Body>
                      */}
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th style={{backgroundColor:'rgb(230, 145, 56)'}}>Reference NO.</th>
                            <th style={{backgroundColor:'rgb(230, 145, 56)'}}>Cutomer</th>
                            <th style={{backgroundColor:'rgb(230, 145, 56)'}}>User</th>
                            <th style={{backgroundColor:'rgb(230, 145, 56)'}}>Handler Model</th>
                            <th style={{backgroundColor:'rgb(230, 145, 56)'}}>Case Type</th>
                            <th style={{backgroundColor:'rgb(230, 145, 56)'}}>Owner</th>
                            <th style={{backgroundColor:'rgb(230, 145, 56)'}}>Issue</th>
                            <th style={{backgroundColor:'rgb(230, 145, 56)'}} onClick={() => {handlerEditVisitDate(task.Id)}}>Visit Customer Date</th>
                            <th style={{backgroundColor:'rgb(230, 145, 56)'}} onClick={()=>{handlerAddUpdate(task.Id);}}>Update</th>
                            <th style={{backgroundColor:'rgb(230, 145, 56)'}}>RequestDescription</th>
                            <th style={{backgroundColor:'rgb(230, 145, 56)'}} onClick={()=>{handlerAddSwResult(task.Id);}}>SW Result</th>
                            <th style={{backgroundColor:'rgb(230, 145, 56)'}}>Number Of Software Version Modifications</th>
                            <th style={{backgroundColor:'rgb(230, 145, 56)'}} onClick={()=>{handlerEditBallAt(task.Id);}}>Ball At</th>
                            <th style={{backgroundColor:'rgb(230, 145, 56)'}} onClick={()=>{handlerEditWho(task.Id);}}>Who</th>
                            <th style={{backgroundColor:'rgb(230, 145, 56)'}} onClick={()=>{handlerEditStatus(task.Id);}}>Status</th>
                            <th style={{backgroundColor:'rgb(230, 145, 56)'}} onClick={()=>{handlerEditClosedDate(task.Id);}}>Closed Date</th>
                            <th style={{backgroundColor:'rgb(230, 145, 56)'}} onClick={()=>{handlerEditRemark(task.Id);}}>Remark / Image</th>
                            {/*
                            <th>Name</th>
                            <th>Contact</th>
                            */}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{task.ReferenceNO}</td>
                            <td>{task.Customer}</td>
                            <td>{task.User}</td>
                            <td>{task.HandlerModel}</td>
                            <td>{task.CaseType}</td>
                            <td>{task.Owner}</td>
                            <td dangerouslySetInnerHTML={{ __html: task.Issue.toHtml()} }></td>
                            <td>{task.VisitCustomerDate}</td>
                            <td dangerouslySetInnerHTML={{ __html: task.Update.toHtmlAndLastBlue()} }></td>
                            <td>{task.RequestDescription}</td>
                            <td dangerouslySetInnerHTML={{ __html: task.SWResult.toHtmlAndLastBlue() } }></td>
                            <td>{task.NumberOfSoftwareVersionModifications}</td>
                            <td>{task.BallAt}</td>
                            <td>{task.Who}</td>
                            <td>{task.Status}</td>
                            <td>{task.ClosedDate}</td>
                            <td dangerouslySetInnerHTML={{ __html: task.RemarkImage.toHtml() } }></td>
                          </tr>
                          
                          {/*
                          <tr><td>Reference NO.</td><td>{task.ReferenceNO}</td></tr>
                          <tr><td>Cutomer</td><td>{task.Cutomer}</td></tr>
                          <tr><td>User</td><td>{task.User}</td></tr>
                          <tr><td>Handler Model</td><td>{task.HandlerModel}</td></tr>
                          <tr><td>Case Type</td><td>{task.CaseType}</td></tr>
                          <tr><td>Owner</td><td>{task.Owner}</td></tr>
                          <tr><td>Issue</td><td>{task.Issue}</td></tr>
                          <tr><td>Visit Customer Date</td><td>{task.VisitCustomerDate}</td></tr>
                          <tr><td>Update</td><td dangerouslySetInnerHTML={{ __html: task.Update.toHtmlAndLastBlue()} }></td></tr>
                          <tr><td>SW Result</td><td dangerouslySetInnerHTML={{ __html: task.SWResult.toHtmlAndLastBlue() } }></td></tr>
                          <tr><td>Number Of Software Version Modifications</td><td>{task.NumberOfSoftwareVersionModifications}</td></tr>
                          <tr><td>Ball At</td><td>{task.BallAt}</td></tr>
                          <tr><td>Who</td><td>{task.Who}</td></tr>
                          <tr><td>Status</td><td>{task.Status}</td></tr>
                          <tr><td>Closed Date</td><td>{task.ClosedDate}</td></tr>
                          <tr><td>Remark / Image</td><td>{task.RemarkImage}</td></tr>
                          <tr><td>Fee Description</td><td>{task.FeeDescription}</td></tr>
                          */}
                        </tbody>
                      </Table>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
          )
        }
      </Accordion>

      {/*
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#Id</th>
            <th>Title</th>
            <th>Owner</th>
            <th>Status</th>
            <th>WaitFor</th>
            <th>Company Name</th>
          </tr>
        </thead>
        <tbody>
          {tbody}
        </tbody>
      </Table>
      */}

      <Modal aria-labelledby="contained-modal-title-vcenter" centered show={modal_visitdate_show} onHide={handleModalVisitDateClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Visit Customer Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Control type="date" ref={visit_date}></Form.Control>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalVisitDateConfirm}>
            Confirm
          </Button>
          <Button variant="secondary" onClick={handleModalVisitDateClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal aria-labelledby="contained-modal-title-vcenter" centered show={modal_update_show} onHide={handleModalUpdateClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label dangerouslySetInnerHTML={{__html: curr_task == null ? '' : curr_task.Update.toHtml()}}></Form.Label>
          <Row style={{ border: "1px" }}>
            <Col md="1">{curr_task == null ? '' : ((curr_task.Update.split('\n').length + 1).toString() + '.')}</Col>
            <Col md="4"><Form.Control type="date" ref={update_date}></Form.Control></Col>
            <Col md="7"><Form.Control type="text" ref={update}></Form.Control></Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalUpdateConfirm}>
            Confirm
          </Button>
          <Button variant="secondary" onClick={handleModalUpdateClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal aria-labelledby="contained-modal-title-vcenter" centered show={modal_swresult_show} onHide={handleModalSwResultClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add SW Result</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label dangerouslySetInnerHTML={{__html: curr_task == null ? '' : curr_task.SWResult.toHtml()}}></Form.Label>
          <Row style={{ border: "1px" }}>
            <Col md="1">{curr_task == null ? '' : ((curr_task.SWResult.split('\n').length + 1).toString() + '.')}</Col>
            <Col md="4"><Form.Control type="date" ref={swresult_date}></Form.Control></Col>
            <Col md="4"><Form.Control type="text" ref={swresult}></Form.Control></Col>
            <Col md="3">
            <Form.Select ref={swresult_ok} aria-label="Default select example">
              <option value="NG">NG</option>
              <option value="OK">OK</option>
            </Form.Select>
            </Col>
          </Row>
          
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalSwResultConfirm}>
            Confirm
          </Button>
          <Button variant="secondary" onClick={handleModalSwResultClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal aria-labelledby="contained-modal-title-vcenter" centered show={modal_ballat_show} onHide={handleModalBallAtClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit BallAt</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form.Select ref={ball_at} aria-label="Default select example">
            <option value="PSMC_Service">PSMC_Service</option>
            <option value="PSMC_Sales">PSMC_Sales</option>
            <option value="NST\NSTT">NST\NSTT</option>
            <option value="Customer">Customer</option>
          </Form.Select>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalBallAtConfirm}>
            Confirm
          </Button>
          <Button variant="secondary" onClick={handleModalBallAtClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal aria-labelledby="contained-modal-title-vcenter" centered show={modal_who_show} onHide={handleModalWhoClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Who</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form.Control type="text" ref={who}></Form.Control>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalWhoConfirm}>
            Confirm
          </Button>
          <Button variant="secondary" onClick={handleModalWhoClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal aria-labelledby="contained-modal-title-vcenter" centered show={modal_status_show} onHide={handleModalStatusClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form.Select ref={status} aria-label="Default select example">
            <option value="Not yet started">Not yet started</option>
            <option value="Processing">Processing</option>
            <option value="Waitting SPEC">Waitting SPEC</option>
            <option value="Waitting PO">Waitting PO</option>
            <option value="Pending">Pending</option>
            <option value="Close">Close</option>
          </Form.Select>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalStatusConfirm}>
            Confirm
          </Button>
          <Button variant="secondary" onClick={handleModalStatusClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal aria-labelledby="contained-modal-title-vcenter" centered show={modal_closeddate_show} onHide={handleModalClosedDateClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Closed Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Control type="date" ref={closed_date}></Form.Control>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalClosedDateConfirm}>
            Confirm
          </Button>
          <Button variant="secondary" onClick={handleModalClosedDateClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal aria-labelledby="contained-modal-title-vcenter" centered show={modal_remark_show} onHide={handleModalRemarkClose} >
        <Modal.Header closeButton>
          <Modal.Title>Edit Remark/Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form.Control as="textarea" rows={3} ref={remark} defaultValue={(curr_task == null) ? '' : curr_task.RemarkImage}></Form.Control>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalRemarkConfirm}>
            Confirm
          </Button>
          <Button variant="secondary" onClick={handleModalRemarkClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default ListJobTasks;
