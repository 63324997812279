import React from 'react';

const IS_USE_GOOGLE_APP = true;

const ResponseReslt = {
  'Success': 0,
  'AuthFailed': 1,
  'NoExistUser': 2,
  'UserPriorityError': 3,
  'NoSuchRefNo': 4,
  'RefNoExsit': 5,
  'NoHandlerModel': 6,
  'RequestDataError': 7,
  'Unknown': 255,
}

var HostName = 'http://localhost:5110';
var UrlLogin = HostName + '/User/login';
var UrlTaskList = HostName + '/JobTask/get-list';

if (IS_USE_GOOGLE_APP === true) {
  HostName = 'https://script.google.com/macros/s/AKfycbxS98bMcaIHpd-nSbqWBYrDJ1S1eCfAvShhFoGgpTebgmKSmTm6lmomhnCvTiigIhFxVA/exec';
  UrlLogin = HostName;
  UrlTaskList = HostName;
}

const TaskContext = React.createContext();

export { ResponseReslt, HostName, TaskContext, IS_USE_GOOGLE_APP, UrlLogin, UrlTaskList };