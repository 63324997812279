import React from 'react';
import { IS_USE_GOOGLE_APP, UrlLogin, UrlTaskList } from './constants'

async function postData(url = '', data = {}) {
  if (IS_USE_GOOGLE_APP !== true) {
    return await fetch(url, {
      method: 'POST',
      mode: 'cors',
      redirect: "follow",
      cache: 'no-cache',
      credentials: "same-origin",
      headers: [
        ["Content-Type", "application/json;charset=utf-8"],
        ["Content-Type", "text/plain;charset=utf-8"]
      ],
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data)
    });
  }
  else {
    return await fetch(url, {
      method: 'POST',
      mode: 'cors',
      redirect: "follow",
      cache: 'no-cache',
      credentials: "same-origin",
      headers: [
        ["Content-Type", "text/plain;charset=utf-8"]
      ],
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data)
    });
  }
}

async function login(data) {
  if (IS_USE_GOOGLE_APP === true) {
    data.Command = 'login';
  }
  return postData(UrlLogin, data);
}

var getTaskList = async (data) => {
  if (IS_USE_GOOGLE_APP === true) {
    data.Command = 'task-list';
  }

  return postData(UrlTaskList, data)
}

var createTask = async (data) => {
  if (IS_USE_GOOGLE_APP === true) {
    data.Command = 'task-create';
  }

  return postData(UrlTaskList, data)
}

var updateTask = async (data) => {
  if (IS_USE_GOOGLE_APP === true) {
    data.Command = 'task-update';
  }

  return postData(UrlTaskList, data)
}

export { postData, login, getTaskList, createTask, updateTask }