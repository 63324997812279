import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Login from "./Login";
import reportWebVitals from './reportWebVitals';
//import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route, BrowserRouter } from "react-router-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
//import { Provider, useSelector, useDispatch } from 'react-redux';

String.prototype.toHtml = function () {
  let s = this;
  /*
  s = s.replace(/\n/g, '</p><p>');
  s = '<p>' + s + '</p>';
  s = s.replace('<p></p>', '');
  */
  //this = s;

  let ss = s.split('\n');

  for (let i = 0; i < ss.length; i++) {
    ss[i] = ss[i] + '<br>';
  }

  s = '';
  for (let i = 0; i < ss.length; i++) {
    s += ss[i]
  }
  return s;
}

String.prototype.toHtmlAndLastBlue = function() {
  let s = this;
  let ss = s.split('\n');

  for (let i = 0; i < (ss.length - 1); i++) {
    ss[i] = ss[i] + '<br>';
  }
  ss[ss.length - 1] = '<font style="color: rgb(0, 0, 255)">' + ss[ss.length - 1] + '</font>';
  /*
  s = s.replace(/\n/g, '</p><p>');
  s = '<p>' + s + '</p>';
  s = s.replace('<p></p>', '');
  //this = s;
  */

  s = '';
  for (let i = 0; i < ss.length; i++) {
    s += ss[i]
  }
  return s;
}

const router = createBrowserRouter(
  [
    {
      path: "/:id",
      element: <App />,
    },
    {
      path: "*",
      element: <Login />,
    },

    /*
    {
      path: "/",
      element: <App />,
      loader: appLoader,
      children: [
        {
          path: "team",
          element: <Team />,
          loader: teamLoader,
        },
      ],
    },
    */
  ]
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
