import React from 'react';
//import { useState, useEffect } from 'react';
import {useRef} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Button, Row, Col, Stack, Form } from 'react-bootstrap';
import { ResponseReslt } from './global/constants';
import { createTask } from './global/functions';


var AddNewTask = React.memo((props) => {
  let ref_no = useRef('');
  let customer = useRef('');
  let user = useRef('');
  let model_ns8000ii = useRef(false);
  let model_ns8000_8h = useRef(false);
  let model_ns8000_4h = useRef(false);
  let model_ns7160w = useRef(false);
  let model_ns7000 = useRef(false);
  let model_ns6000 = useRef(false);
  let model_s9 = useRef(false);
  let model_s9hs = useRef(false);
  let model_sx2400 = useRef(false);
  let case_type = useRef('');
  let owner = useRef('');
  let issue = useRef('');
  let request = useRef('');

  let clearContext = () => {
    ref_no.current.value = '';
    customer.current.value = '';
    user.current.value = '';
    model_ns8000ii.current.checked = false;
    model_ns8000_8h.current.checked = false;
    model_ns8000_4h.current.checked = false;
    model_ns7160w.current.checked = false;
    model_ns7000.current.checked = false;
    model_ns6000.current.checked = false;
    model_s9.current.checked = false;
    model_s9hs.current.checked = false;
    model_sx2400.current.checked = false;
    case_type.current.value = '';
    owner.current.value = '';
    issue.current.value = '';
    request.current.value = '';
  }

  let handlerConfirm = () => {


    let data = {
      'UserId': props.user.Id,
      'TaskData':{
        'ReferenceNO': ref_no.current.value,
        'Customer': customer.current.value,
        'User':user.current.value,
        'HandlerModel': [],
        'CaseType':case_type.current.value,
        'Owner': owner.current.value,
        'Issue': request.current.value,
        'RequestDescription': request.current.value,
      }
    }

    if(model_ns8000ii.current.checked) data.TaskData.HandlerModel.push('NS8000II');
    if(model_ns8000_4h.current.checked) data.TaskData.HandlerModel.push('NS8000_4H');
    if(model_ns8000_8h.current.checked) data.TaskData.HandlerModel.push('NS8000_8H');
    if(model_ns7160w.current.checked) data.TaskData.HandlerModel.push('NS7160W');
    if(model_ns7000.current.checked) data.TaskData.HandlerModel.push('NS7000');
    if(model_ns6000.current.checked) data.TaskData.HandlerModel.push('NS6000');
    if(model_s9.current.checked) data.TaskData.HandlerModel.push('S9');
    if(model_s9hs.current.checked) data.TaskData.HandlerModel.push('S9+HS');
    if(model_sx2400.current.checked) data.TaskData.HandlerModel.push('SX2400');
    
    console.log(customer.current.value);
    console.log(customer.current.value);

    createTask(data)
    .then((response) => {
      if(response.ok){
        response.json().then((result) => {
          if(result.Result !== ResponseReslt.Success) {
            alert(result.Message);
            return;
          }

          props.show.callback(false);
          clearContext();
        }).catch((error) => {
          console.error('Error:', error);
        });
      }
    });
  };

  let handlerCancel = () => {
    props.show.callback(false);

    clearContext();
  };


  return(
    <div style={props.show.display ? {display: "inline"} : {display: "none"}}>
      {/*
      <Row md="10">
        <Col>
        </Col>
        <Col lg="1">
          <Button variant="danger" onClick={() => {props.show.callback(false);}}>Cancel</Button>{' '}
        </Col>
        <Col lg="1">
          <Button variant="primary" onClick={() => {props.show.callback(false);}}>Comfirm</Button>{' '}
        </Col>
      </Row>
      */}

      <Stack  gap="3" className="col-md-5 mx-auto">
        <Row>
          <Col><Button variant="primary" onClick={handlerConfirm}>Comfirm</Button>{' '}</Col>
          <Col><Button variant="danger" onClick={handlerCancel}>Cancel</Button>{' '}</Col>
        </Row>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Reference NO.</Form.Label>
          <Form.Control type="text" ref={ref_no} placeholder="Enter Reference NO." />
          <Form.Text className="text-muted">
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Cutomer</Form.Label>
          <Form.Select ref={customer} aria-label="Default select example">
            <option value="">Open this select menu</option>
            <option value="Ardentec">Ardentec</option>
            <option value="ASEKH">ASEKH</option>
            <option value="ASECL">ASECL</option>
          </Form.Select>
          <Form.Text className="text-muted">
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>User</Form.Label>
          <Form.Control ref={user} type="text" placeholder="Enter User" />
          <Form.Text className="text-muted">
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Handler Model</Form.Label>
          <Row style={{textAlign: "left"}}>
            <Col><Form.Check type="checkbox" ref={model_ns8000ii} label="NS8000II" /></Col>
            <Col><Form.Check type="checkbox" ref={model_ns8000_8h} label="NS8000_8H" /></Col>
            <Col><Form.Check type="checkbox" ref={model_ns8000_4h} label="NS8000_4H" /></Col>
          </Row>
          
          <Row style={{textAlign: "left"}}>
            <Col><Form.Check type="checkbox" ref={model_ns7160w} label="NS7160W" /></Col>
            <Col><Form.Check type="checkbox" ref={model_ns7000} label="NS7000" /></Col>
            <Col><Form.Check type="checkbox" ref={model_ns6000} label="NS6000" /></Col>
          </Row>

          <Row>
            <Col> {' '} </Col>
          </Row>
          
          <Row style={{textAlign: "left"}}>
            <Col><Form.Check type="checkbox" ref={model_s9} label="S9" /></Col>
            <Col><Form.Check type="checkbox" ref={model_s9hs} label="S9+HS" /></Col>
            <Col><Form.Check type="checkbox" ref={model_sx2400} label="SX2400" /></Col>
          </Row>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Case Type</Form.Label>
          <Form.Select ref={case_type} aria-label="Default select example">
            <option value="">Open this select menu</option>
            <option value="SW_Request">SW_Request</option>
            <option value="SW_Bug">SW_Bug</option>
            <option value="HW">HW</option>
          </Form.Select>
          <Form.Text className="text-muted">
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Owner</Form.Label>
          <Form.Control ref={owner} type="text" placeholder="Enter Owner" />
          <Form.Text className="text-muted">
          </Form.Text>
        </Form.Group>          

        <Form.Group className="mb-3">
          <Form.Label>Issue</Form.Label>
          <Form.Control ref={issue} type="text" placeholder="Enter Issue" />
          <Form.Text className="text-muted">
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Request Description</Form.Label>
          <Form.Control ref={request} type="text" placeholder="Enter Request Description" />
          <Form.Text className="text-muted">
          </Form.Text>
        </Form.Group>



        <Row>
          <Col><Button variant="primary" onClick={handlerConfirm}>Comfirm</Button>{' '}</Col>
          <Col><Button variant="danger" onClick={handlerCancel}>Cancel</Button>{' '}</Col>
          {/*
          <Col><Button variant="primary" onClick={() => {props.show.callback(false);}}>Comfirm</Button>{' '}</Col>
          <Col><Button variant="danger" onClick={() => {props.show.callback(false);}}>Cancel</Button>{' '}</Col>
          */}
        </Row>
        
      </Stack>

    </div>
  );
});

export default AddNewTask;