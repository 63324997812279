import React from 'react';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
import Stack from 'react-bootstrap/Stack';
import {FormControl, Spinner} from 'react-bootstrap';

import { ResponseReslt } from './global/constants';
import { postData, login } from './global/functions';
import sha512 from './global/sha512.min';

function Login() {
  let [show, setShow] = useState(false);
  let [busy, setBusy] = useState(false);
  let username = useRef();
  let password = useRef();
  const navigate = useNavigate();
  let [result_message, setResultMessage] = useState('');


  let handlerLogin = () => {
    let data = {
      'Username': username.current.value,
      'Password': sha512(password.current.value, 'hex')
    };
    

    setBusy(true);
    login(data)
    .then((response) => {
      if(response.ok){
        response.json().then((result) => {
          setShow(false);
          if(result.Result !== ResponseReslt.Success) {
            setBusy(false);
            setShow(true);
            setResultMessage(result.Message);
            return;
          }
          navigate('/' + result.Data.Id, {state:{currUser: result.Data}});
        }).catch((error) => {
          console.error('Error:', error);
        });
      }
    });
  };

  let handlerKeyUp = (e) => {

    if(e.keyCode !== 13) return;

    handlerLogin();
  };
  

  return (
      <Container>
        <Stack gap={3} hidden={busy}>
          <h1>任務管理系統，請登入</h1>
          <InputGroup className="mb-3">
            <InputGroup.Text>帳號</InputGroup.Text>
            <Form.Control type="text" ref={username} placeholder="請輸入帳號" />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroup.Text>密碼</InputGroup.Text>
            <FormControl type="password" ref={password} onKeyUp={handlerKeyUp} placeholder="請輸入密碼" />
          </InputGroup>

          <Button variant="warning" onClick={handlerLogin}>登入</Button>{' '}

          <Alert variant="danger" show={show}>
            <Alert.Heading>{result_message}</Alert.Heading>
            <hr />
            <p>
              請洽系統管理員
            </p>
          </Alert>
        </Stack>

        <Spinner animation="border" hidden={!busy}/>
        <Spinner animation="grow" hidden={!busy}/>
      </Container>
  );
}

export default Login;